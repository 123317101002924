$(function () {
    $("#nav-toggle").on("click", function() {
        $("nav").toggleClass("open");
        return false;
    });

    $(".nav--expander").on("click", function () {
        $(this).closest(".has-children").toggleClass("open");
        return false;
    });

    /* Scroll top */
    $('#scroll-top').click(function () {
        $("html, body").animate({ scrollTop: 0 }, 600);
        return false;
    });
    /* End Scroll top */

    $(".scroller--recent-news").lightSlider({
        item: 3,
        adaptiveHeight: true,
        responsive: [
            {
                breakpoint: 960,
                settings: {
                    item: 2
                }
            },
            {
                breakpoint: 560,
                settings: {
                    item: 1
                }
            }
        ]
    });
});